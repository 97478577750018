/**************************************************************************************************
    FileName  : exerciseRouter.tsx
    Description

    Update History 
      2024.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
import ExerciseWritePage from "./ExerciseWritePage";
import ExerciseListPage from "./ExerciseListPage";
import ExerciseDetailPage from "./ExerciseDetailPage";
import ExerciseEditPage from "./ExerciseEditPage";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Routes                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////
const routes = [
    {
		path : "/exercise/write",
		element: <ExerciseWritePage/>,
	},
	{
		path : "/exercises",
		element: <ExerciseListPage/>,        
	},
    {
        path : "/exercises/:exerciseId",
        element: <ExerciseDetailPage/>,
    },
    {
        path : "/exercises/:exerciseId/edit",
        element: <ExerciseEditPage/>,
    },
];

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default routes;