/**************************************************************************************************
    FileName  : Home.tsx
    Description

    Update History 
    	2023.11     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { appNavigator } from "libs/stdlib";
import "./Home.scss";
import React, { useEffect } from "react";

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Class Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function Home() {
	
	globalThis.applySmoothScreenPage(useEffect);
	
    return (
		<div id="pageHome" className="main-bg position-re">		 	
			<Slider/>
			<GuideSection/>
			<Class/>
			<Exercise/>
		</div>
    );
}


///////////////////////////////////////////////////////////////////////////////
// Slider Section
function Slider() {
	return (
		<header className="header-creative">
				<div className="container ontop">
							<div className="row justify-content-center full-height">
								<div className="col-lg-3 d-flex align-items-end">
									<div>
										<img src="assets/imgs/svg-assets/claw.svg" alt=""/>										
										<img src="assets/imgs/home/code-geek-2680204_640.png"/>
									</div>
								</div>
								<div className="col-lg-6 valign">
									<div className="caption text-center full-width md-mb50">
										<div className="mb-30">
											<svg className="svg-animation star" width="100" height="100" viewBox="0 0 100 100"
												fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
												<line y1="50" x2="100" y2="50" vectorEffect="non-scaling-stroke"
													stroke="currentColor" style={{"--index": 1, "--transform": "30deg"} as any /*eslint-disable-line @typescript-eslint/no-explicit-any */ }></line>
												<line y1="50" x2="100" y2="50" vectorEffect="non-scaling-stroke"
													stroke="currentColor" style={{"--index": 2, "--transform": "60deg"} as any /*eslint-disable-line @typescript-eslint/no-explicit-any */}></line>
												<line y1="50" x2="100" y2="50" vectorEffect="non-scaling-stroke"
													stroke="currentColor" style={{"--index": 3, "--transform": "90deg"} as any /*eslint-disable-line @typescript-eslint/no-explicit-any */}></line>
												<line y1="50" x2="100" y2="50" vectorEffect="non-scaling-stroke"
													stroke="currentColor" style={{"--index": 4, "--transform": "120deg"} as any /*eslint-disable-line @typescript-eslint/no-explicit-any */}></line>
												<line y1="50" x2="100" y2="50" vectorEffect="non-scaling-stroke"
													stroke="currentColor" style={{"--index": 5, "--transform": "150deg"} as any /*eslint-disable-line @typescript-eslint/no-explicit-any */}></line>
												<line y1="50" x2="100" y2="50" vectorEffect="non-scaling-stroke"
													stroke="currentColor" style={{"--index": 6, "--transform": "180deg"} as any /*eslint-disable-line @typescript-eslint/no-explicit-any */}></line>
											</svg>
										</div>
										<h4 className="fw-300 mb-15">BGProgramming</h4>
										<h1 className="fw-600 d-rotate wow">
											<span className="rotate-text" style={{fontFamily :  "Nanum Pen Script"}}>프로그래밍...</span>
											<span className="rotate-text" style={{fontFamily :  "Nanum Pen Script"}}>끝없는 길의 이정표</span>
										</h1>
									</div>
								</div>
								<div className="col-lg-3">
									<div>
										<img src="assets/imgs/home/computer-1873831_640.png" alt=""/>
									</div>
									<div className="mt-30 md-hide">
										<div className="text-center hover-this">
											<div className="circle-button hover-anim">
												<div className="rotate-circle fz-30 text-u">
													<svg className="textcircle" viewBox="0 0 500 500">
														<defs>
															<path id="textcircle1"
																d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z">
															</path>
														</defs>
														<text>
															<textPath xlinkHref="#textcircle1" textLength="900">Design - Coding - Debug -</textPath>
														</text>
													</svg>
												</div>
												<div className="in-circle text-center">
													<h2><i className="fas fa-laptop-code"></i></h2>
												</div>
											</div>
										</div>
										<div className="text-center">
											<h6>프로그래머 <span className="fz-14">is</span></h6>
											<p className="fz-13">다른 사람을 꿈꾸게 만드는 사람이다.<br/>그렇기에 끊임없는 노력과 책임이 필요한 직업이다.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
				<div className="bg-pattern bg-img" data-background="assets/imgs/patterns/graph.png"></div>
        </header>


	);
}


///////////////////////////////////////////////////////////////////////////////
// Guide Section
function GuideSection() {
	return (
		<section className="about section-padding main-bg">			
            <div className="container ontop">
				<div className="row">
					<div className="col-lg-5 valign">
						<div className="about-circle-crev md-hide">
							<div className="circle-button">
								<div className="rotate-circle fz-16 ls1 text-u">
									<svg className="textcircle" viewBox="0 0 500 500">
										<defs>
											<path id="textcircle"
												d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z">
											</path>
										</defs>
										<text>
											<textPath xlinkHref="#textcircle" textLength="930" >프로그래밍 - 끝없는 길의 이정표 ⦁ 프로그래밍 - 끝없는 길의 이정표  ⦁  프로그래밍 - 끝없는 길의 이정표 ⦁</textPath>
										</text>
									</svg>
								</div>
							</div>
							<div className="half-circle-img">
								<img src="assets/imgs/home/signage-2873882_1280.jpg" alt=""/>
							</div>
						</div>
					</div>
					<div className="col-lg-7 valign">
						<div className="cont sec-lg-head">
							<h6 className="dot-titl mb-20">My Duty</h6>
							<h2 className="d-slideup wow">
								<span className="sideup-text"><span className="up-text">저는 언젠가 여러분들이</span></span>								
							</h2>
							<div className="row">
								<div className="col-lg-12">
									<div className="text mt-20">
										<p>											
											각자의 프로그래밍 철학을 가지고 자신의 길을 걷기를 바랍니다.<br/>
											저는 그 전까지 길을 안내해 줄 수 있는 가이드가 될 수 있도록 노력하겠습니다.
										</p>
									</div>									
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </section>

	);
}


///////////////////////////////////////////////////////////////////////////////
// Class
function Class() {
	return (
		<section className="section-className services main-bg ontop bord-thin-top bord-thin-bottom">
            <div className="container-fluid">
                <div className="row">
					<div className="item-bord offset-lg-2 col-lg-4 col-md-6">
						<div className="item-container">
							<div className="icon-img-70 mb-40">
								<img src="assets/imgs/serv-icons/6.png" alt=""/>
							</div>
							<h6 className="mb-15 item-title">Fundamental</h6>
							<p className="item-description">입문자분들이 알아야할 핵심 사항을 알려드립니다.</p>
							<a className="arrow mt-40" style={{cursor:"pointer"}} onClick={()=>appNavigator.learning.goFundamentalList()}>
								<span className="circle">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
											fill="currentColor"></path>
									</svg>
								</span>
								<span className="fz-12 text-u ml-10">GOTO</span>
							</a>
						</div>
					</div>
					<div className="item-bord col-lg-4 col-md-6 ">
						<div className="item-container">
							<div className="icon-img-70 mb-40">
								<img src="assets/imgs/serv-icons/3.png" alt=""/>
							</div>
							<h6 className="mb-15 item-title">Business Skills</h6>
							<p className="item-description">실무에서 사용하는 사항들입니다.</p>
							{/* <a href="#" className="arrow mt-40">
								<span className="circle">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
											fill="currentColor"></path>
									</svg>
								</span>
								<span className="fz-12 text-u ml-10">Read More</span>
							</a> */}
						</div>
					</div>
                </div>
            </div>
        </section>
	);
}

function Exercise() {
	return (
		<section>
			<div className="panel sub-bg">
						<div className="container mt-60">
							<div className="row justify-content-center">
								<div className="col-lg-10">
									<div className="sec-lg-head text-center">
											<h6 className="dot-titl mb-10">Exercise</h6>										
											<h2 className=" fw-700 mt-20">
												<span>연습문제를 풀어 봅시다.</span>
											</h2>
											<a 	onClick={()=>appNavigator.exercise.goList()} 
												style={{cursor:"pointer"}}
												className="butn-circle colorbg-2 d-flex align-items-center text-center mt-50 m-auto">
												<div className="full-width">
													<span><svg width="18" height="18" viewBox="0 0 18 18" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
																fill="currentColor"></path>
														</svg></span>
													<span className="full-width">Continue</span>
												</div>
												<img src="assets/imgs/svg-assets/circle-star.svg" alt="" className="circle-star"/>
											</a>
										
									</div>
								</div>
							</div>
						</div>
						<div className="bg-pattern bg-img" data-background="assets/imgs/patterns/abstact-BG.png"></div>
			</div>
		</section>

	);
}