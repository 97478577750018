
///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Type Definitions                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
export enum HttpMethod {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete'
}


export interface APIError {
    code : number;
    message : number;    
}

export type APICallbackSuccess<T> = (data : T)=>void;
export type APICallbackError = (e:unknown)=>void;


