/**************************************************************************************************
    FileName  : FundamentalDetailPage.tsx
    Description

    Update History 
    	2024.07     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import "./LearningDetailPage.scss";
import React, {useEffect, useState} from "react";
import { 
    IconButton, Stack, Typography, Menu, MenuItem,
    Box
} from '@mui/material';
import {MyInfo, api, appNavigator, pathParamReader, utils} from "libs/stdlib";
import { LearningDetailData } from "types";
import { useParams } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SHSTextViewer from "components/SHSText/SHSTextViewer";
import CommentList from "components/CommentList";

// import Grid from '@mui/material/Unstable_Grid2';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Learning Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function FundamentalDetailPage() {
    const urlParams = useParams();
    const learningId = pathParamReader.readPositiveInt(urlParams.learningId);
    const [detailInfo, setDetailInfo] = useState<LearningDetailData | null>(null);
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
    const myInfo = MyInfo.getInstace().useMyInfo();

    useEffect(()=>{
        (async ()=>{
            await api.getWithCallback<LearningDetailData>(`/api/learnings/${learningId}`, (data)=>{
                setDetailInfo(data);
                console.log(data);
            });
        })();
        utils.gotoTop();
    }, [learningId]);

    globalThis.applySmoothScreenPage(useEffect);

    if( detailInfo === null )
        return <></>;


    function showMenu(event: React.MouseEvent<HTMLElement>) {
        setMenuAnchor(event.currentTarget);
    }
    
    function closeMenu() {
        setMenuAnchor(null);
    }

    function onEditExerciseClick() {
        appNavigator.learning.goEditPage(learningId);        
    }

    async function onDeleteExerciseClick() {
        await api.deleteWithCallback(`/api/learnings/${learningId}`, ()=>{
            appNavigator.learning.goList();
        })        
    }

    return (
		<Stack id="pageLearningDetail-20240718">

            <Stack className="title-container">
                <Typography variant="h2">{detailInfo.title}</Typography>
                <div className="flex-fill"></div>
                
                {myInfo &&
                    <React.Fragment>
                        <IconButton onClick={showMenu}><MoreVertIcon/></IconButton>
                        <Menu
                            anchorEl={menuAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(menuAnchor)}
                            onClose={closeMenu}
                        >
                            <MenuItem onClick={onEditExerciseClick}>수 정</MenuItem>
                            <MenuItem onClick={onDeleteExerciseClick}>삭 제</MenuItem>
                        </Menu>

                    </React.Fragment>                    
                }                
            </Stack>
                
            
            <hr/>
            <SHSTextViewer description={detailInfo.description}/>        
            <hr/>

            {detailInfo.exerciseList && 0 < detailInfo.exerciseList.length &&
                <ExerciseLink detailInfo={detailInfo}/>
            }

            {/* <CommentList rootPostId={1} /> */}
        </Stack>
    );
}


function ExerciseLink(props : {detailInfo : LearningDetailData}) {
    return (
        <Stack className="relative-exercise-list-container">
            <Typography variant="h4">연습문제</Typography>
            <Stack className="exercise-list">
                {props.detailInfo.exerciseList.map((exerciseInfo)=>{
                    return(
                            <Box className="card-container" key={exerciseInfo.id} onClick={()=>appNavigator.exercise.goDetailPage(exerciseInfo.id)}>
                                <Typography className="item-title" variant="h5" >
                                    {exerciseInfo.title}
                                </Typography>                                                                    
                            </Box>
                        )
                    })
                }
            </Stack>
            <hr/>
        </Stack>
    );
}
