/**************************************************************************************************
    FileName  : UserAPI.ts
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { MyInfoData } from "types";
import APIBaseRequester from "./APIBaseRequester";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Type Definitions                                           //
///////////////////////////////////////////////////////////////////////////////////////////////////
export interface LoginData {
    token: string;
    userInfo: MyInfoData,
}
  
 
///////////////////////////////////////////////////////////////////////////////////////////////////
//                               Class Implementation                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class UserAPI extends APIBaseRequester{
    public async login(email : string, password : string) : Promise<LoginData> {
        return await this.post<LoginData>('/api/user/login', {email, password});
    }

}