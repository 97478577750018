/**************************************************************************************************
    FileName  : Navbar.tsx
    Description

    Update History       
    	2023.12     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./Navbar.scss";
// Import for React
import React from "react";
// Import for Types
import { MyInfoData, IRootState} from "types";
// Import for Material UI
import { Button } from "@mui/material";
// Import for Package Library
import { useSelector } from 'react-redux';
// Import for Project Library
import { appNavigator, useMyInfo } from "libs/stdlib";
// Import for Project Modules
import authManager from "modules/AuthManager";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                               Class Implementation                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function Navbar() {	
    /////////////////////////////////////////////////////////////////////////////
	// Const
	const myInfo = useMyInfo();
    
	return (
        <nav id="appNavBar" className="navbar navbar-expand-lg static main-bg">
            <div className="container">
                {/* <!-- Logo --> */}
                <a className="logo" style={{width:"140px", marginRight: "60px"}} onClick={()=>appNavigator.goHome()}>
                    <img src="/assets/imgs/logo-light.png" alt="logo"/>
                </a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"><i className="fas fa-bars"></i></span>
                </button>

                {/* <!-- navbar links --> */}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">                        
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button"
                                aria-haspopup="true" aria-expanded="false"><span className="rolling-text">학습</span></a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" onClick={()=>appNavigator.learning.goFundamentalList()}>기초개념</a>                                
                            </div>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link">
                                <span className="rolling-text" onClick={()=>appNavigator.learning.goList()}>
                                    학습
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link">
                                <span className="rolling-text" onClick={()=>appNavigator.exercise.goList()}>
                                    연습
                                </span>
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" onClick={()=>appNavigator.goHome()}><span className="rolling-text">프로젝트</span></a>
                        </li> */}
                    </ul>
                </div>


                {/* <!-- login --> */}
                {!myInfo && 
                    <div className="collapse navbar-collapse nav-right-panel" >
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a 
                                    className="nav-link"                                 
                                    onClick={()=>appNavigator.auth.goLoginPage()}><span className="rolling-text">로그인</span></a>
                            </li>
                        </ul>
                    </div>
                }
                {myInfo && 
                    <div className="collapse navbar-collapse nav-right-panel" >
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Button
                                    onClick={()=>authManager.logout()}
                                    variant="outlined"
                                >
                                    로그아웃
                                </Button>
                            </li>                        
                        </ul>
                    </div>
                }
                
            </div>
		</nav>		
	);
}