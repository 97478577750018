/**************************************************************************************************
    FileName  : SHSCompositeText.tsx
    Description

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import {ComponentType, TextStyle, ViewerStyle, ElementType, TextElement} from "../Define";

///////////////////////////////////////////////////////////////////////////////////////////////////
// This is abstract static class function
export const getCompositeTextType = function () {
    return ComponentType.compositeText;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
export interface SHSCompositeTextProps {
    viewerStyle? : ViewerStyle;
    items : TextElement[];
    onMentionClick? : (userName : string)=>void;
    onLinkClick? : (url : string)=>void;
}

export default class SHSCompositeText extends React.Component<SHSCompositeTextProps> {
    render() {        
        const props = this.props;
        function getClass(textStyle : TextStyle) {            
            switch(textStyle) {
                case TextStyle.large :
                case TextStyle.quotation:
                    return textStyle;
                case TextStyle.extraLarge :
                    return "extra-large";                
                default :
                    return textStyle;
            }
        }

        function getStyle(textStyle : TextStyle) {            
            const elementStyle : React.CSSProperties = {} ;
            elementStyle.color = props.viewerStyle && props.viewerStyle.textColor ? props.viewerStyle.textColor : "inherit";

            switch(textStyle) {
                case TextStyle.extraLarge:
                    elementStyle.fontWeight = "bold";
                    elementStyle.fontSize = 18;
                    elementStyle.margin = "32px 0px 12px";
                    break;
                case TextStyle.code:
                    elementStyle.fontFamily = "NanumGothicCoding";
                    elementStyle.padding = "16px 8px";
                    elementStyle.border = "1px solid #454545";
                    elementStyle.borderRadius = "4px";
                    elementStyle.margin = "16px 0px"
                    elementStyle.width = "100%"
                    break;                
                default:
                    elementStyle.fontFamily = props.viewerStyle && props.viewerStyle.textFontFamily ? props.viewerStyle.textFontFamily : "inherit";
                    break;
            }

            return elementStyle;
        }
        
        
        return(
        	<pre className="composite-text" style={{margin : this.props.viewerStyle?.textMargin}}>
        		{
        			this.props.items.map((item, index) => {
                        if( item.type === ElementType.text  ) {
                            if(  !item.textStyle || item.textStyle===TextStyle.normal ) {
                                return (
                                    <span key={index} className={getClass(item.textStyle)} style={{
                                            color : this.props.viewerStyle && this.props.viewerStyle.textColor ? this.props.viewerStyle.textColor : "inherit",
                                            fontFamily : this.props.viewerStyle && this.props.viewerStyle.textFontFamily ? this.props.viewerStyle.textFontFamily : "inherit"
                                        }}>{item.text}</span>
                                );
                            } else {
                                return (
                                    <div className="styled-text" key={index}>
                                        {item.textStyle===TextStyle.bullet &&
                        					<div style={{marginRight:4, display : "flex", alignItems : "center", height:"100%", marginTop : 2}}>•</div>
                        				}

                        				{item.textStyle===TextStyle.numberedList &&
                        					<div style={{marginRight:4, display : "flex", alignItems : "center", height:"100%" }}>
                                                {item.listNumber}.
                                            </div>
                        				}

                        				{item.textStyle===TextStyle.quotation &&
                        					<div style={{
                                                backgroundColor: this.props.viewerStyle ? 
                                                    this.props.viewerStyle.quotationLineColor : "white",
                                                width : 1, 
                            
                                                height:"100%", 
                                                position:"absolute"
                                            }}> </div>
                        				}

                                        <div
                                            key={index}
                                            className={getClass(item.textStyle)}
                                            style={getStyle(item.textStyle)}
                                        >{item.text}</div>
                                    </div>
                                );
                            }
                        } 
                        else if( item.type === ElementType.inlineLink )
        					return <span key={index} className="inline-link" onClick={()=>{
                                if( this.props.onLinkClick && item.url ) {
                                    this.props.onLinkClick(item.url);
                                }
                            }}>{item.url}</span>
        				else
	                    	return <React.Fragment key={index}>{item.text}</React.Fragment>
		            })
        		}
        	</pre>
        );

    }
}
