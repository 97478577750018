/**************************************************************************************************
    FileName  : SHSLink.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import {ComponentType, ViewerStyle} from '../Define';


function getSiteString(url : string) : string{
    const devidedByProtocol = url.split("//");
    if (devidedByProtocol.length > 1 && devidedByProtocol[1].split("/").length > 0) {
        return devidedByProtocol[1].split("/")[0];
    } else {
        return "";
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
// This is abstract static class function
export const getLinkType = function () {
    return ComponentType.link;
};
///////////////////////////////////////////////////////////////////////////////////////////////////

interface SHSLinkProps {
    preview : {
        url? : string;
        image? : string;
        title? : string;
    };
    onLinkClick? : (url : string)=>void;
    viewerStyle : ViewerStyle;
    style : any;    // eslint-disable-line @typescript-eslint/no-explicit-any
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class SHSLink extends React.Component<SHSLinkProps> {
    render() {
        const props = this.props;
        const previewInfo = props.preview;

        
        function handleOnClick(e : React.MouseEvent<HTMLDivElement, MouseEvent>) {
            e.stopPropagation();
            if( props.onLinkClick && props.preview.url) {
                props.onLinkClick(props.preview.url);
            } else if( props.preview.url ) {
                window.open(props.preview.url, '_blank');
            }
        }
        
        return(
            <React.Fragment>
            {previewInfo.url && previewInfo.image && previewInfo.title && 
                <div onClick={handleOnClick} className="link-container" style={{
                        backgroundColor : (props.viewerStyle && props.viewerStyle.cardBackgroundColor) ? props.viewerStyle.cardBackgroundColor : "inherit",
                        margin : props.viewerStyle?.bodyMargin,
                    }}>
                    <img src={previewInfo.image} className="link-preview" alt={previewInfo.image} />
                    <div className="info-container">
                        <div className="link-title" style={{color:props.viewerStyle ? props.viewerStyle.textColor : "inherit"}} >{previewInfo.title}</div>
                        <div className="link-site" >{getSiteString(previewInfo.url).toLowerCase()}</div>
                    </div>
                </div>
            }
            
            {previewInfo.url && (!previewInfo || !previewInfo.image || !previewInfo.title) &&
                <div className="plain-link-container" onClick={handleOnClick} style={props.style}>
                    {previewInfo.url}
                </div>
            }


            {(previewInfo.url === null || previewInfo.url === undefined) &&
                <div className="invalid-url">Invalid URL</div>
            }
            </React.Fragment>
        );
    }
}
