export enum FileState {
    tmp = "tmp",
    active = "active"
}

export interface FileInfo {
    id : number;    
    name : string;
    originName : string;
    url : string;
    metadata? : {
        width : number;
        height : number;
    }
    state : FileState
}




export interface ImageInfo {
    id : number;
    mimetype: string;
    name: string;
    originName: string;
    ownerSdeId?: number;
    state?: string;
    url: string;
}

export type StateLabel<T extends string> = {
    [Key in T]: string;
}
