/**************************************************************************************************
    FileName  : types.tsx
    Description

    Update History       
    	2023.10     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
import { useRouteError } from "react-router-dom";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                               Class Implementation                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function ErrorPage() {
	const error = useRouteError();
	console.error(error);

	return (
		<div id="error-page">
		<h1>Oops!</h1>
		<p>Sorry, an unexpected error has occurred.</p>
		<p>
			<i>{error.statusText || error.message}</i>
		</p>
		</div>
	);
}