/**************************************************************************************************
    FileName  : AppNavigator.tsx
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { NavigateFunction, NavigateOptions } from "react-router-dom";


class AppNavigateCore {
    private _navigate: NavigateFunction;

    constructor(navigate: NavigateFunction) {
        this._navigate = navigate;        
    }
    
    public navigate(urlOrDelta: string | number, options?: NavigateOptions) {        
        if (this._navigate === null) {
            setTimeout(() => this.navigate(urlOrDelta, options), 100);
        } else {
            if (typeof urlOrDelta === 'string') {
                this._navigate(urlOrDelta as string, options);
            } else {
                this._navigate(urlOrDelta as number);
            }
        }
    }
}


class AppNavigator {
    private _navigateCore : AppNavigateCore | null = null;

    private _auth : AuthNavigator | null = null;
    private _exercise : ExerciseNavigator | null = null;
    private _learning : LearningNavigator | null = null;

    public get auth() : AuthNavigator {
        if( this._auth === null )
            throw new Error("Null auth navigator");
        return this._auth;        
    }

    public get exercise() : ExerciseNavigator {
        if( this._exercise === null )
            throw new Error("Null exercise navigator");
        return this._exercise;
    }

    public get learning() : LearningNavigator {
        if( this._learning === null )
            throw new Error("Null learning navigator");
        return this._learning;
    }

    
    private navigate(urlOrDelta: string | number, options?: NavigateOptions) {
        if( this._navigateCore !== null )
            this._navigateCore.navigate(urlOrDelta, options);
        
    }

    public init(navigate: NavigateFunction) {
        const navigateCore = new AppNavigateCore(navigate);
        this._navigateCore = navigateCore;
        this._auth = new AuthNavigator(navigateCore);
        this._exercise = new ExerciseNavigator(navigateCore);
        this._learning = new LearningNavigator(navigateCore);
    }
    
    public go(url : string, replace? : boolean) {        
        if( this._navigateCore !== null )
            this._navigateCore.navigate(url, { replace });
    }
    
    public goBack() {
        this.navigate(-1);
    }

    public goHome() {
        this.navigate('/');
    }

    public goUserAgreement() {
        this.navigate(`/user-agreement`);
    }

    public goPrivatePolicy() {
        this.navigate(`/private-policy`);
    }

        


    public goLoginPage() {
        this.navigate('/');
    }

    

    public goDashboardPage() {
        this.navigate('/dashboard');
    }

    public goVideoPage() {
        this.navigate('/video');
    }
}



class BaseNavigator {
    private _core : AppNavigateCore;
    constructor ( core : AppNavigateCore) {
        this._core = core;
    }

    protected navigate(urlOrDelta: string | number, options?: NavigateOptions) {
        this._core.navigate(urlOrDelta, options);
    }
    
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                             Auth Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AuthNavigator extends BaseNavigator {
    public goLoginPage() {
        console.log("goLoginPage....")
        this.navigate('/login');
    }

    public goRequestSignupPage() {
        this.navigate('/signup/request');
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                             Exercise Class Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
class ExerciseNavigator extends BaseNavigator {
    public goList() {
        this.navigate('/exercises');
    }
    public goWritePage() {
        this.navigate('/exercise/write');
    }
    public goDetailPage(exerciseId : number) {
        this.navigate(`/exercises/${exerciseId}`);
    }
    public goEditPage(exerciseId : number) {
        this.navigate(`/exercises/${exerciseId}/edit`);
    }
}

class LearningNavigator extends BaseNavigator {
    public goList() {
        this.navigate('/learnings');
    }
    public goFundamentalList() {
        this.navigate('/learnings');
    }
    public goWritePage() {
        this.navigate('/learning/write');
    }
    public goDetailPage(learningId : number) {
        this.navigate(`/learnings/${learningId}`);
    }
    public goEditPage(learningId : number) {
        this.navigate(`/learnings/${learningId}/edit`);
    }
}






const _instance = new AppNavigator();
export default _instance;