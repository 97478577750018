/**************************************************************************************************
    FileName  : SHSImage.ts
    Description

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React  from 'react';
import './Editor.scss';
import { IconButton, CircularProgress } from '@mui/material';
import { ElementType, SHSElementProps } from "../Define";
import loadImage from 'blueimp-load-image';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { FileInfo } from 'types';
import gassert from 'libs/gassert';

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
const MAX_IMAGE_WIDTH = 1440;

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////


interface SHSImageProps  extends SHSElementProps{
    onProvideImageData : (imageId : number)=>FileInfo;
    notifyUpdateDataInfo : (elementKey : number, newValue : any)=>void;     // eslint-disable-line @typescript-eslint/no-explicit-any
    notifyObjectLoaded : (elementKey : number, elementType : ElementType)=>void;
    requestDeleteElement : (elementKey : number)=>void;
    siteUrl : string;
    requestUploadImage : (file : File)=>any;    // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface Size {
    width : number;
    height : number;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Class Implementation                                       //
///////////////////////////////////////////////////////////////////////////////////////////////////

// https://stackoverflow.com/questions/3814231/loading-an-image-to-a-img-from-input-file
export default class SHSImage extends React.Component<SHSImageProps> {
    refImageContainer : any = null;     // eslint-disable-line @typescript-eslint/no-explicit-any

    state = {
        isLoading : false,
        src : ""
    }

    constructor(props : SHSImageProps) {
        super(props);

		this.onFocus = this.onFocus.bind(this);
		this.getContainerStyle = this.getContainerStyle.bind(this);
		this.onDeleteImageClick = this.onDeleteImageClick.bind(this);
        this.refImageContainer = React.createRef();

        this.state = {
            isLoading : false,
            src : ""
        };

        (async ()=>{
            console.info("props.data>>>>", props.data);
            if( props.data.file ) {
                this.state.isLoading = true;
                await this.init(props.data.file);
                this.state.isLoading = false;
            } 
            else if( props.data.id ){
                const res = await props.onProvideImageData(props.data.id);
                console.info("image res ", res);
                console.info("image res ", props.siteUrl + res.url);

                this.setState({
                    src : props.siteUrl + res.url,
                    width : res.metadata?.width || 0,
                    height : res.metadata?.height || 0,
                });                
            }
		})();
    }

    getImageOrientation(imgBlob : any) {    // eslint-disable-line @typescript-eslint/no-explicit-any
        return new Promise((resolve) => {
            loadImage.parseMetaData(
                imgBlob,
                (data) => {
                    if (data.exif === null || data.exif === undefined) {
                        resolve(null);
                    } else {
                        const orientation = data.exif.get('Orientation');                        
                        resolve(orientation);
                    }
                }
            );
        });
    }
    loadImageAndRotateImageIfNeeded (imageFile : any) {     // eslint-disable-line @typescript-eslint/no-explicit-any
        function _getImageSizeWithMaxImage(width? : number, height? : number) {
            if( width === undefined || height === undefined ) {
                return { width : 0, height : 0 }
            }

            if( width < MAX_IMAGE_WIDTH )
                return {width, height};
            else {
                // MAX_WIDTH : x  = width : height
                // x =  MAX_WIDTH * height / width
                return {
                    width : MAX_IMAGE_WIDTH,
                    height :MAX_IMAGE_WIDTH * height / width
                };
            }
        }

        const _this = this;     // eslint-disable-line @typescript-eslint/no-this-alias
        return new Promise((resolve) => {
            loadImage(
				imageFile,
				(canvas, data) => {
                    gassert(data !== undefined);
                    if( data === undefined  )
                        return ;


					const orientation = data.exif ? data.exif.get("Orientation") : null;
					let size = null;
					if( orientation && orientation === 6 )
						size = _getImageSizeWithMaxImage(data.originalHeight, data.originalWidth);
					else
						size = _getImageSizeWithMaxImage(data.originalWidth, data.originalHeight);

					_this.setState({imageWidth : size.width, imageHeight : size.height});
					_this.props.notifyUpdateDataInfo(
						_this.props.elementKey,
						{ width : size.width, height : size.height }
					);
					resolve( (canvas as any).toDataURL() );     // eslint-disable-line @typescript-eslint/no-explicit-any
				},
				{orientation: true, maxWidth: MAX_IMAGE_WIDTH, canvas: true, meta : true}
			);
        });
    }
    getImageSize (imageFile : any) : Promise<Size>{     // eslint-disable-line @typescript-eslint/no-explicit-any
        function _getImageSizeWithMaxImage(width? : number, height? : number) : Size {
            if( width === undefined || height === undefined )
                return {width : 0, height : 0};

            if( width < MAX_IMAGE_WIDTH )
                return {width, height};
            else {
                // MAX_WIDTH : x  = width : height
                // x =  MAX_WIDTH * height / width
                return {
                    width : MAX_IMAGE_WIDTH,
                    height :MAX_IMAGE_WIDTH * height / width
                };
            }
        }

        return new Promise((resolve) => {
            loadImage(
				imageFile,
				(canvas, data) => {
                    gassert(data !== undefined);
                    if( data === undefined ) return ;

					const orientation = data.exif ? data.exif.get("Orientation") : null;
					let size = null;
					if( orientation && orientation === 6 )
						size = _getImageSizeWithMaxImage(data.originalHeight, data.originalWidth);
					else
						size = _getImageSizeWithMaxImage(data.originalWidth, data.originalHeight);

					resolve(size);
				},
				{orientation: true, maxWidth: MAX_IMAGE_WIDTH, canvas: true, meta : true}
			);
        });
    }
    rotateImageFromOriginOrientation (orientation : loadImage.ExifTagValue, originImageFileOrUrl : any) {   // eslint-disable-line @typescript-eslint/no-explicit-any
        return new Promise((resolve) => {
            loadImage(originImageFileOrUrl,
            (canvas) => {
                // console.log('Exif data: ', data.exif.getAll() )
                // console.info("Orientation", data.exif.get("Orientation") );
                resolve((canvas as any).toDataURL());       // eslint-disable-line @typescript-eslint/no-explicit-any
            },
            {orientation: true, maxWidth: MAX_IMAGE_WIDTH, canvas: true, meta : true});
        });
    }
    convertFileToDataUrl (file : any) {     // eslint-disable-line @typescript-eslint/no-explicit-any
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", (e : any) => {      // eslint-disable-line @typescript-eslint/no-explicit-any
                resolve(e.target.result);
            });
            reader.readAsDataURL(file);
        });
    }



    onFocus() {
        this.props.notifySelectedItem(this.props.elementKey);
    }


    async init(file : File) {        
        if( !this.props.requestUploadImage )
            return ;


        // const imageSize = await this.getImageSize(file);
        // function makeUpdateFileData(info : FileInfo) {
        //     return {
        //         ...info,
        //         fileName : file.name,
        //         fileType : file.type,
        //         width : imageSize.width,
        //         height : imageSize.height,
        //     };
        // }

        try {
            const res = await this.props.requestUploadImage(file);            
            this.setState({src : this.props.siteUrl + res.url, name : file.name});
            this.props.notifyUpdateDataInfo( this.props.elementKey, res );
        } catch(e) {

        }

        this.props.notifyObjectLoaded(this.props.elementKey, ElementType.image);
    }



	getContainerStyle() {
        return {display : "flex"};
	}

	onDeleteImageClick() {
		this.props.requestDeleteElement(this.props.elementKey);
	}




    render() {
        if( !this.state )
            return <React.Fragment/>;

        console.info("this.state.src>>>", this.state.src);

        return (
			<div className="image-container" style={this.getContainerStyle()} tabIndex={0} onFocus={this.onFocus}  >

                {!this.state.src && this.state.isLoading &&
                    <CircularProgress className="loading-progress"/>
                }

                {this.state.src &&
                    <div className="image-wrapper">
                        <img
                            className="image"
                            src={this.state.src}
                            ref={this.refImageContainer}
                            alt={this.state.src}
                        />
                        <IconButton className="delete-icon" onClick={this.onDeleteImageClick}>
                            <HighlightOffOutlinedIcon/>
                        </IconButton>
                    </div>
                    
                }

				<div className="flex-row-fill"/>

                

				
			</div>


        );
    }
}
