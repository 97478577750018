/**************************************************************************************************
    FileName  : reducer.ts
    Description

    Update History       
      2023.10     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Package Library
import { combineReducers } from 'redux';
// Import for Reducer Internal Modules
import user from './user/reducer';
import misc from './misc/reducer';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                        Constants                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
const rootReducer = combineReducers({
    user,  misc  
});


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export type IRootState = ReturnType<typeof rootReducer>;
export default rootReducer;