/**************************************************************************************************
    FileName  : ExerciseEditPage.tsx
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./ExerciseEditPage.scss";
// Import for React
import React  from 'react';
import { useEffect, useState, useRef } from "react";
// Import for Route
import { useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Button, TextField, Typography
} from "@mui/material";
// Import for Project Types
// import { PostDetailData } from "types";
// Import for Project Base
import { api, appNavigator, appToast, pathParamReader } from "libs/stdlib";
// Import for Project Modules

import SHSTextEditor, {TextEditorRefType} from "components/SHSText/SHSTextEditor";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  PostWritePage Implementation                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
function ExerciseEditPage() {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const urlParams = useParams();    
    const exerciseId = pathParamReader.readPositiveInt(urlParams.exerciseId);    
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");        
    const refTextEditor : TextEditorRefType = useRef(null);
    const [isDataLoad, setDataLoad] = useState<boolean>(false);
    

    /////////////////////////////////////////////////////////////////////////////
    // API    
    useEffect( ()=>{
        (async ()=>{
            await api.common.getExerciseDetailWithCallback(exerciseId, (data)=>{                
                setTitle(data.title);
                setDescription(data.description);
                setDataLoad(true);
            })
        })();        
    }, [exerciseId]);

    // Editor가 생성될 때 파싱 및 데이터 로드가 시작되므로
    // 데이터가 로드된 후 Editor가 작동되도록 한다.     
    if( isDataLoad === false )
        return <></>;


    /////////////////////////////////////////////////////////////////////////////
    // Functions
    async function onEditExerciseClick() {        
        const titleParam = title.trim();
        console.info("titleParam>>>", titleParam);
        if( titleParam.length === 0 )
            return appToast.error("제목을 입력해 주십시오.");
        
        const encodeData = await refTextEditor.current?.encodeAndConfirmTmpImage();        
        await api.putWithCallback(`/api/exercises/${exerciseId}`, {title : titleParam, description : encodeData?.encodedText}, 
            ()=>{
                appToast.success("글을 수정했습니다.");
                appNavigator.goBack();
            }
        );
        
        // await api_updatePost(titleParam, encodeData?.encodedText, encodeData?.relativeFiles);
    }


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="postEditPage-20240614">            
            <Typography className="title-label">제목 *</Typography>
            <TextField className="title-text" value={title} onChange={(e)=>setTitle(e.target.value)  }/>

            <Typography className="description-label">내용</Typography>
            <SHSTextEditor refEditor={refTextEditor} description={description} />

            <Button 
                className="btn-write" 
                variant="outlined" 
                onClick={onEditExerciseClick}
            >글수정</Button>
        </Stack>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default ExerciseEditPage;
  