/**************************************************************************************************
    FileName  : AppAlert.tsx
    Description

	TODO 
		반환하는 결과를 SweetAlertResult가 아닌 자신의 타입으로 한번 감싸는것이 더 좋다.

    Update History
    	2024.07     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Imports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Package Library
import Swal, {SweetAlertIcon, SweetAlertResult} from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                            Types                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export enum EnumAlertIcon {
	success = 'success',
	error = 'error',
	warning = 'warning',
	info = 'info',
	question = 'question'
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Class Implementation                                      //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AppAlert {
	///////////////////////////////////////////////////////////////////////////
    //                              Meber Variables                          //
    ///////////////////////////////////////////////////////////////////////////
	private _alert : typeof Swal;

	///////////////////////////////////////////////////////////////////////////
    //                                Constructor                            //
    ///////////////////////////////////////////////////////////////////////////
	constructor(){
		this._alert = withReactContent(Swal).mixin({
			customClass: {
				confirmButton: 'btn btn-alert-primary',
				cancelButton: 'btn btn-alert-default'
			},
			buttonsStyling: false
		});
	}

	///////////////////////////////////////////////////////////////////////////
    //                            Interface functions                        //
    ///////////////////////////////////////////////////////////////////////////
	// Description 	: 삭제 대화상자 출력
	// Input 		: None
	// Return 		: SweetAlertResult
	public async showDeleteQuestionDialog() : Promise<SweetAlertResult> {
		return await this.showQuestionDialog('정말로 삭제하시겠습니까?', "삭제하면 복구할 수 없습니다.", undefined, "삭제");
	}


	// Description 	: 질문 대화상자 출력
	// Input		: title, icon, confirmButtonText, cancelButtonText
	// Return 		: SweetAlertResult
	public showQuestionDialog(title : string, text : string, icon? : SweetAlertIcon, confirmButtonText? : string, cancelButtonText? : string  ) : Promise<SweetAlertResult> {
		if( icon === undefined ) icon = "warning";
		if( confirmButtonText === undefined ) confirmButtonText = "예";
		if( cancelButtonText === undefined ) cancelButtonText = "아니오";
		
		return this._alert.fire({
			title: title,
			text: text,
			icon: icon,
			showCancelButton: true,
			confirmButtonText,
			cancelButtonText,
			reverseButtons : true,
		});    
	}

	// Description 	: 확인 대화상자 출력
	// Input		: title, icon, confirmButtonText
	// Return 		: SweetAlertResult
	public showConfirmDialog(title : string, text : string, icon? : SweetAlertIcon, confirmButtonText? : string) : Promise<SweetAlertResult> {
		if( icon === undefined ) icon = "warning";
		if( confirmButtonText === undefined ) confirmButtonText = "확인";    
		
		return this._alert.fire({
			title: title,
			text: text,
			icon: icon,
			confirmButtonText,
			reverseButtons : true
		});
	}

	// Description 	: 확인 대화상자 출력
	// Input		: title, icon, inputValue : 초기 입력값, confirmButtonText
	// Return 		: SweetAlertResult
	public async showInputDialog(title : string, text? : string, inputValue? : string, confirmButtonText? : string, cancelButtonText? : string) : Promise<SweetAlertResult> {    
		if( confirmButtonText === undefined ) confirmButtonText = "확인";
		if( cancelButtonText === undefined ) cancelButtonText = "취소";
		
		return await this._alert.fire({
			title: title,
			text: text,
			confirmButtonText,
			cancelButtonText,
			input: 'text',
			inputValue,
			showCancelButton : true,
			reverseButtons : true
		});
	}
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Exports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _instance= new AppAlert();
export default _instance;