/**************************************************************************************************
    FileName  : App.tsx
    Description

    Update History       
      2023.10     BGKim     Create
**************************************************************************************************/


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Imports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
import MyInfo from "modules/MyInfo";
import api, {HttpMethod, APIError, DOMAIN} from "./api";
import DataStorage from "modules/DataStorage";
import utils from "./UtilEx";
import define from "./define";
import appToast from "components/AppToast";
import appAlert, {EnumAlertIcon} from "components/AppAlert";
import appNavigator from "modules/AppNavigator";
import {error} from "./error";
import pathParamReader from "./PathParamReader";
import gassert from "./gassert";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
const dataStorage = DataStorage.getInstance();
const useMyInfo = MyInfo.getInstace().useMyInfo;


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Exports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
export {
    MyInfo, useMyInfo,
    api,
    HttpMethod,
    dataStorage,
    DOMAIN,
    utils,
    define,
    appToast,
    appAlert, EnumAlertIcon,
    appNavigator,
    error,
    pathParamReader,
    gassert
}
export type { APIError}