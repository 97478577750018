/**************************************************************************************************
    FileName  : Editor.ts
    Description

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React  from 'react';
import './Editor.scss';
import { IconButton } from '@mui/material';
import { SHSElementProps, ElementType, YoutubeType } from "../Define";
import Youtube from 'react-youtube';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface SHSYoutubeProps extends SHSElementProps{
	notifyUpdateDataInfo : (elementKey : number, newValue : any)=>void;
	notifyObjectLoaded : (elementKey : number, elementType : ElementType)=>void;
	requestDeleteElement : (elementKey : number)=>void;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                              	SHSYoutube Implementation                                    //
///////////////////////////////////////////////////////////////////////////////////////////////////

export default class SHSYoutube extends React.Component<SHSYoutubeProps> {
	state : {		
		youtubeId : string;		
		youtubeLink : string;
		youtubeType : YoutubeType;
	};

	constructor(props : SHSYoutubeProps) {
		super(props);
		
		this.onFocus = this.onFocus.bind(this);
		this.onDeleteElementClick = this.onDeleteElementClick.bind(this);

		const youtubeIdTmp = props.data.youtubeLink.split( "watch?v=")[1];
		let youtubeType = YoutubeType.default;
		let youtubeId = "";
				
		if(  youtubeIdTmp === undefined ) {
			youtubeType = YoutubeType.shorts;
			youtubeId = props.data.youtubeLink.split( "shorts/")[1];
		} else {
			youtubeId = youtubeIdTmp.split( "&")[0];
		}
				
        this.state = {                        
            youtubeLink : props.data.youtubeLink,
			youtubeId : youtubeId,
			youtubeType
		};
		props.notifyUpdateDataInfo(  props.elementKey, this.state);
	}

	componentDidMount() {		
		this.props.notifyObjectLoaded(this.props.elementKey, ElementType.image);
	}

    onFocus() {
        this.props.notifySelectedItem(this.props.elementKey);
	}

	onDeleteElementClick() {
		this.props.requestDeleteElement(this.props.elementKey);
	}


    render() {		
        return (
            <div className="youtube-container">                
				<Youtube
                    className="spacehub-youtube"                    
                    videoId={this.state.youtubeId}
                    opts={{
                        playerVars: {                            
                            playsinline: 1,
                        }
                    }}
                />
				<IconButton className="delete-icon" onClick={this.onDeleteElementClick}>
					<HighlightOffOutlinedIcon/>
				</IconButton> 

            </div>
        );
    }

}
