/**************************************************************************************************
    FileName  : ExerciseListPage.tsx
    Description

    Update History 
    	2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./ExerciseListPage.scss";
// Import for React
import React, { useEffect, useState } from "react";
// Import for Types
import { MyInfoData, ServerListData, ExerciseData, IRootState  } from "types";
// Import for Material UI
import {  Stack, Typography, styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
// Import for package library
import { useSelector } from 'react-redux';
// Import for Images
import AiGuide from "assets/imgs/ai_guide.png";
import { appNavigator, api } from "libs/stdlib";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Class Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));


export default function ExerciseListPage() {
	/////////////////////////////////////////////////////////////////////////////
	// Const
	const myInfo : MyInfoData | null = useSelector((state: IRootState) => state.user);	
	const [exerciseList, setExerciseList] = useState<ExerciseData[]>([]);

	
	

	useEffect(()=>{		
		(async ()=>{
			await api.postWithCallback<ServerListData<ExerciseData>>(
				"/api/exercises/filter", 
				{lastItemId : -1, numOfPage : 150}, 
				(data)=>{					
					setExerciseList(data.list);					
				}
			);
		})();
	}, []);

	globalThis.applySmoothScreenPage(useEffect);

	

	


	return (
		<Stack id="pageExerciseListPage" sx={{ flexGrow: 1 }}>

			<Stack className="title-container">
				<img className="img-ai-guide" src={AiGuide} ></img>
				<Stack>
					<Typography variant="h2">코딩 연습을 합시다. </Typography>
					<Typography variant="subtitle2"  mt={1} >
						외국어를 할 때 눈으로만 읽고 대화를 잘 할수 있을까요?<br/>
						꾸준히 듣고 말하기 연습을 해야 대화를 할 수 있을 겁니다.<br/>
						프로그래밍도 마찬가지입니다.<br/>
						이론만 읽거나 들어서는 개발이 늘지 않습니다.<br/>
						많은 시간을 고민하고 노력한 시간이 코드에 녹아들게 됩니다.<br/>
					</Typography>					
				</Stack>
				
				<div className="flex-fill"></div>
				{myInfo && 
					<Button className="btn-write" variant="contained" size="large" onClick={()=>appNavigator.exercise.goWritePage()}>글쓰기</Button>
				}
			</Stack>
			

			<Grid container spacing={1} className="list-container" >

				{exerciseList.map((item)=>{
					return (
						<Grid xs={4} key={item.id}>
							<Item className="card-item">
								<Card className="card-container">
									<CardContent>
										<Typography className="item-title" variant="h3"  gutterBottom  onClick={()=>appNavigator.exercise.goDetailPage(item.id)}>
											{item.title}
										</Typography>
										<pre className="item-description" onClick={()=>appNavigator.exercise.goDetailPage(item.id)}>
											{item.description}
										</pre>																
									</CardContent>									
									
									<Typography className="progress-label" variant="body2" color="text.secondary">미진행</Typography>									
								</Card>
							</Item>
							
						</Grid>
					);
				})}
			</Grid>
		</Stack>
	);
  }