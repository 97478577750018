// gassert assert
function gassert(condition: boolean) {
    if (condition === true) return;

    const errorPanel = document.createElement('div');
    errorPanel.id = "hdAssertErrorLayer";
    errorPanel.innerText = "Assert Failed.\nShow console for detail";
    window.document.body.appendChild(errorPanel);

    const e = new Error();
    console.error(e);
}

export default gassert;