/**************************************************************************************************
    FileName  : exerciseRouter.tsx
    Description

    Update History 
      2024.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
import LearningWritePage from "./LearningWritePage";
import LearningListPage from "./LearningListPage";
import LearningDetailPage from "./LearningDetailPage";
import LearningEditPage from "./LearningEditPage";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Routes                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////
const routes = [
    {
        path : "/learning/write",
        element: <LearningWritePage/>,
    },
    {
        path : "/learnings",
        element: <LearningListPage/>,
    },
    {
        path : "/learnings/:learningId",
        element: <LearningDetailPage/>,
    },
    {
        path : "/learnings/:learningId/edit",
        element: <LearningEditPage/>,
    },
    
];

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default routes;