/**************************************************************************************************
    FileName  : ListPage.tsx
    Description

    Update History 
    	2023.12     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import "./LearningListPage.scss";
import React, { useEffect, useState } from "react";
// Import for Types
import { MyInfoData, IRootState, ServerListData, LearningData, LearningCategory  } from "types";
import {  Button, Chip, Stack, Typography } from '@mui/material';
// Import for package library
import { useSelector } from 'react-redux';
import { appNavigator, api, gassert } from "libs/stdlib";


import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import SHSSimpleTextViewer from "components/SHSText/SHSSimpleTextViewer";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Learning Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function ListPage() {
	const myInfo : MyInfoData | null = useSelector((state: IRootState) => state.user);
	const [learingList, setLearningList] = useState<LearningData[]>([]);

	globalThis.applySmoothScreenPage(useEffect);
	
	useEffect(()=>{
		(async ()=>{
			await api.postWithCallback<ServerListData<LearningData>>(
				"/api/learnings/filter",
				{lastItemId : -1, numOfPage : 150}, 
				(data)=>{					
					setLearningList(data.list);
				}
			);
		})();
		
	}, []);

	
	function getCategoryLabel(category : LearningCategory) {
		switch(category) {
			case LearningCategory.fundamental:	return "fundamantal";
			case LearningCategory.practice:		return "practice";
			case LearningCategory.tip: 			return "tip";
			case LearningCategory.tool: 		return "tool";
			default:
				gassert(false);
				throw "Invaild Learning Category";
		}
	}

	// 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
	function getCategoryColor(category : LearningCategory) {
		switch(category) {
			case LearningCategory.fundamental:	return "error";
			case LearningCategory.practice:		return "success";
			case LearningCategory.tip: 			return "info";
			case LearningCategory.tool: 		return "primary";
			default:
				gassert(false);
				return "default";
		}
	}
	
	
	
    return (
		<Stack id="pageLearningList-20240716">

			<Stack className="guide-container">
				{/* <Stack>
					<Typography variant="h2">
						기본다지기
					</Typography>
					<Typography variant="body2" mt={1}>
						개발을 배울 때 내용을 이해하고 습득해야 이것을 활용하여 적용할 수 있습니다.<br/>
						프로그래밍의 이해를 위한 기본을 배워 봅시다.
					</Typography>
				</Stack> */}

				<Stack>
					<Typography variant="h2">
						기초 · 팁 · 도구 · 실습
					</Typography>
					<Typography variant="body2" mt={1}>
						개발은 암기가 아닙니다. 암기를 통해서는 자신의 실력이 증진되지 않습니다.<br/>
						현업에서는 프로젝트마다 제각기 특징이 있는 상황에 부딪히게 되며 각기 다른 솔루션을 필요합니다.<br/>
						이는 암기로 해결할 수 없으며 공부해서 배우는 원리를 응용해야 해결이 가능합니다.<br/>
						동작을 이해하고 이것을 직접 개발해야 손에 익고 머리에 각인이 됩니다.<br/>
						기초개념 및 코딩 팁, 개발 도구와 따라하기로 만들어보는 실습을 통해 개념을 이해하여 개발 실력을 향상 시키세요.<br/>
					</Typography>
				</Stack>
				

				<div className="flex-fill"></div>
				{myInfo && 
					<Button className="btn-write" variant="contained" size="large" onClick={()=>appNavigator.learning.goWritePage()}>글쓰기</Button>
				}

			</Stack>
			

			<Grid container spacing={3} className="list-container" >

				{learingList.map((item)=>{
					return (
						<Grid xs={3} key={item.id} onClick={()=>appNavigator.learning.goDetailPage(item.id)}>
							<Paper className="card-item">
								<Card className="card-container">
									<Stack className="card-header">
										<Chip label={getCategoryLabel(item.category)} color={getCategoryColor(item.category)} size="small"/>										
									</Stack>
									<CardContent className="card-body">
										<Typography className="item-title" variant="h3"  gutterBottom >
											{item.title}
										</Typography>
										<SHSSimpleTextViewer description={item.description} />											
									</CardContent>
								</Card>
							</Paper>
							
						</Grid>
					);
				})}
			</Grid>

		</Stack>
    );
}

