/**************************************************************************************************
    FileName  : Define.ts
    Description

    Update History 
      2023.06     BGKim     Create
**************************************************************************************************/

export enum KeyCode {
	Enter = 13,
	Up = 38,
	Down = 40,
}

export enum ElementType {
	text = 'text',
	image = 'image',
	link = 'link',
	gif = 'gif',
	youtube = 'youtube',
	inlineMention = 'inline-mention',
	inlineLink = 'inline-link',
}


// text viewer element type
export enum ComponentType {
	compositeText = 'composite-text',
	image = 'image',
	link = 'link',
	gif = 'gif',
	youtube = 'youtube'
}

export enum TextStyle {
	normal = "normal",
	large = "large",
	extraLarge = "extraLarge",
	code = "code",

	quotation = "quotation",
	bullet = "bullet",
	numberedList = "numbered-list"	
}

export interface ViewerStyle {
    textColor? : string;
    bodyMargin? : number;
    imageBorderRadius? : number; 
    cardBackgroundColor? : string;
    textFontFamily? : string;
	textMargin? : number;
	quotationLineColor? : string;
}

export interface ParseElement {
    type : ElementType;
}

export interface TextElement extends ParseElement {
	text : string;
	textStyle : TextStyle;
	userName? : string; // for mention
	url? : string; // for link
	listNumber? : number;	// for numberdList

}


export interface BuildItem {    
    componentType : ComponentType;
    items? : ParseElement[];   // for compositeText
}

export interface Rect {
	x : number;
	y : number;
	width : number;
	height : number;
}

export interface SHSElementProps {
	elementKey : number;
    data : any;		// eslint-disable-line @typescript-eslint/no-explicit-any
	notifySelectedItem : (elementKey : number)=>void;	

}

export interface MetaTagInfo {
	title : string;
	url : string;
	type : string;
	image : string;
	description : string;	
}


export enum YoutubeType {
	default="default",
	shorts = "shorts"
}

export interface SHSYoutubeData {
	id : string;
	youtubeLink : string;
	youtubeType : YoutubeType;
}

