/**************************************************************************************************
    FileName  : api/index.ts
    Description
      API Module

    Update History       
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Types
import { HttpMethod, APIError, APICallbackSuccess, APICallbackError } from "./apiTypes";
import { ServerResponseError } from "libs/error";
import { DefaultErrorType } from 'types';
// Import for Package Library
import axios, {AxiosInstance} from 'axios';
// Import for Project Modules
import DataStorage from 'modules/DataStorage';
import appToast from "components/AppToast";
// Import for API Internal Modules
import UserAPI from "./UserAPI";
import APIBaseRequester from './APIBaseRequester';
import CommonAPI from "./CommonAPI";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
const DOMAIN: string = process.env.REACT_APP_SERVER_API_DOMAIN || "";
const SERVER_NAME : string = process.env.REACT_APP_SERVER_NAME || "";
const REQUEST_TIMEOUT = 10000;


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Private Constsnts                                        //
///////////////////////////////////////////////////////////////////////////////////////////////////



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                   Class Implementation                                        //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AppAPI {
    ///////////////////////////////////////////////////////////////////////////
    // Member Variables
    private _axiosInst : AxiosInstance;
    private _baseRequster : APIBaseRequester;
    
    ///////////////////////////////////////////////////////////////////////////
    // Public readonly property
    public readonly user : UserAPI;
    public readonly common : CommonAPI;

    ///////////////////////////////////////////////////////////////////////////
    // Constructor
    public constructor() {
        // Init axios instance and requester
        this._axiosInst = axios.create({
            baseURL: DOMAIN,
            timeout: REQUEST_TIMEOUT
        });
        this._baseRequster = new APIBaseRequester(this._axiosInst);
        this.user = new UserAPI(this._axiosInst);
        this.common = new CommonAPI(this._axiosInst);
                

        const token = DataStorage.getInstance().get<string>("API", "token");
        if( token !== null )
            this.setAccessToken(token);
    }

    ///////////////////////////////////////////////////////////////////////////
    //                      Public Interface Functons                        //
    ///////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////
    // Default Method
    public async get<T>(url: string): Promise<T> {
        return await this._baseRequster.get<T>(url);
    }
    public async post<T>(url: string, reqData?: unknown): Promise<T> {
        return await this._baseRequster.post<T>( url, reqData);
    }
    public async put<T>(url: string, reqData?: unknown): Promise<T> {
        return await this._baseRequster.put<T>( url, reqData);
    }
    public async delete<T>(url: string): Promise<T> {
        return await this._baseRequster.delete<T>(url);
    }


    ///////////////////////////////////////////////////////////////////////////
    // get, post, put, delete with callback function
    public async getWithCallback<T>(url: string, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._baseRequster.getWithCallback<T>( url, fnCallbackSuccess, errorMessage);
    }
    public async postWithCallback<T>(url: string, reqData?: unknown, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._baseRequster.postWithCallback<T>( url, reqData, fnCallbackSuccess, errorMessage);
    }
    public async putWithCallback<T>(url: string, reqData?: unknown, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._baseRequster.putWithCallback<T>( url, reqData, fnCallbackSuccess, errorMessage);
    }
    public async deleteWithCallback<T>(url: string, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._baseRequster.deleteWithCallback<T>( url, fnCallbackSuccess, errorMessage );
    }
    


    
    ///////////////////////////////////////////////////////////////////////////
    // Access Token
    // TODO : instance 생성이 아니라 기존 instance에서 헤더만 수정하던지 아니면 
    // 생성후 base 및 각 모듈에서 사용하는 axios instance를 update 해주는 구문이 있어야 한다.
    private _accessToken : string | undefined;
    public setAccessToken(token : string) {
        DataStorage.getInstance().set("API", "token", token);        
        this._axiosInst = axios.create({
            baseURL: DOMAIN,
            timeout: REQUEST_TIMEOUT,
            headers : {
                common : {
                    'Authorization' : token
                }
            }
        });
        this._updateRequesterAxiosInstance(this._axiosInst);
        this._accessToken = token;        
    }
    public removeAccessToken() {
        DataStorage.getInstance().delete("API", "token");        
        this._axiosInst = axios.create({
            baseURL: DOMAIN,
            timeout: REQUEST_TIMEOUT,            
        });
        this._updateRequesterAxiosInstance(this._axiosInst);
        this._accessToken = undefined;
    }
    public getAccessToken() {
        return this._accessToken;
    }



    ///////////////////////////////////////////////////////////////////////////
    //                      Private Functons                                 //
    ///////////////////////////////////////////////////////////////////////////
    private _updateRequesterAxiosInstance(axiosInstance : AxiosInstance) {
        this._baseRequster._updateAxiosInstanceForAPI(axiosInstance);
        this.user._updateAxiosInstanceForAPI(axiosInstance);
        this.common._updateAxiosInstanceForAPI(axiosInstance);
    }
    
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _defaultInstace = new AppAPI();
export default _defaultInstace;
export { HttpMethod, DOMAIN, SERVER_NAME };
export type { APIError }
