/**************************************************************************************************
    FileName  : SHSTextEditor.ts
    Description

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React  from 'react';
import Editor from "./EditorComponents/Editor";
import { MetaTagInfo } from "./Define"
import { DOMAIN, api } from 'libs/stdlib';
import { FileInfo } from "types";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface SHSTextEditorProps {
    refEditor : React.RefObject<Editor>;
    description? : string;
}
export type TextEditorRefType = React.RefObject<Editor>;


export default function SHSTextEditor(props : SHSTextEditorProps) {
    async function requestConfirmTmpImages(fileIds : number[]) {
        if( fileIds.length === 0 )
            return ;

        await api.post(
            "/api/file/post/tmp/image-confirm",
            {fileIds}
        );        
    }

    async function requestUploadImage(file : File) : Promise<FileInfo> {
        const formData = new FormData();
        formData.append("image", file);
        const imageInfo = await api.post<FileInfo>(
            "/api/file/post/tmp/image", 
            formData            
        );        
        return imageInfo;
    }


    async function api_getPreviewMetaTagInfo(url : string) : Promise<MetaTagInfo> {
        return await api.post<MetaTagInfo>(
            "/api/misc/preview/info",
            {url}            
        );
    }


    async function requestLinkMetaTagInfo(url : string, cb : (info : any)=>void) {
        const previewInfo = await api_getPreviewMetaTagInfo(url);        
        cb(previewInfo);
    }


    async function onProvideImageData(fileId : number) {
        return await api.get<FileInfo>(`/api/files/${fileId}`);
    }


    return(
        <Editor
            ref={props.refEditor}
            description={props.description}
            isShowToolbar={true}
            requestUploadImage={requestUploadImage}
            requestLinkMetaTagInfo={requestLinkMetaTagInfo}            
            requestConfirmTmpImages={requestConfirmTmpImages}
            onProvideImageData={onProvideImageData}
            siteUrl={DOMAIN}
            

        />
    );
}
