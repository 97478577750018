import Util from "./Util";

class UtilsEx extends Util {
   	public gotoTop() {
		window.scrollTo({top:0, behavior : "instant"});
   	}

	public getDateString(utcTime : number) : string {
        const dt : Date = new Date(utcTime * 1000);
        return `${dt.getFullYear()}.${dt.getMonth()+1}.${dt.getDate()}`;
    }
}


const _instance = new UtilsEx();
export default _instance;
