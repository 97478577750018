/**************************************************************************************************
    FileName  : Footer.tsx
    Description

    Update History       
    	2023.12     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { Stack, Typography } from "@mui/material";
import { appNavigator } from "libs/stdlib";
import React from "react";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                               Class Implementation                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function Footer() {
	

	return (        
        <footer>
                      <div className="footer-container">
                      <div className="container pb-80 pt-80 ontop mt-70">
                                <div className="row">                                  
                                    <div className="md-mb50">
                                        <div className="tit mb-20">
                                            <Stack flexDirection={"row"} alignItems={"center"}>
                                                <Typography variant="h3">Youtube</Typography>
                                                <a href="https://www.youtube.com/channel/UCVLat88v4HcUbrXzpUfAPEQ" target="_blank" rel="noreferrer"><Typography ml={2} >BG Programming</Typography></a>                                                
                                            </Stack>                                            
                                        </div>
                                    </div>                                    
                                </div>                                
                            </div>
                          <div className="sub-footer pt-40 pb-40 bord-thin-top ontop">
                              <div className="container">
                                  <div className="row">
                                      <div className="col-lg-8" style={{display:"flex", alignItems:"center"}}>
                                            <div className="logo">
                                                <a >
                                                    <img src="/assets/imgs/logo-light.png" alt=""/>
                                                </a>
                                            </div>
                                            <div style={{marginLeft:"32px"}}>
                                                <span onClick={()=>appNavigator.goUserAgreement()} style={{cursor:"pointer"}}>이용약관</span> 
                                                <span style={{margin:"0px 8px"}}>|</span>
                                                <span onClick={()=>appNavigator.goPrivatePolicy()} style={{cursor:"pointer"}}>개인정보 처리방침</span>
                                            </div>
                                      </div>                                                        
                                      <div className="col-lg-4">                                            
                                            <div className="copyright d-flex" style={{justifyContent:"flex-end", alignItems:"center"}}>
                                                <div>
                                                    <p className="fz-13">Copyright © BGProgramming All rights reserved.</p>
                                                </div>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
        </footer>
	);
}