/**************************************************************************************************
    FileName  : CommonAPI.ts
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { ExerciseData, MyInfoData } from "types";
import APIBaseRequester from "./APIBaseRequester";
import {APICallbackSuccess} from "./apiTypes";

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Type Definitions                                           //
///////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////
//                               Class Implementation                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class CommonAPI extends APIBaseRequester{
    public async getExerciseDetailWithCallback<T>(exerciseId : number, fnCallbackSuccess : APICallbackSuccess<ExerciseData> ){
        await this.getWithCallback<ExerciseData>(`/api/exercises/${exerciseId}`, fnCallbackSuccess);
    }

    public async getLearningDetailWithCallback<T>(learningId : number, fnCallbackSuccess : APICallbackSuccess<ExerciseData> ){
        await this.getWithCallback<ExerciseData>(`/api/learnings/${learningId}`, fnCallbackSuccess);
    }
    

}