/**************************************************************************************************
    FileName  : AuthManager.tsx
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import MyInfo from "modules/MyInfo";
import {api} from "libs/stdlib";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AuthManager{
    public async login(email : string, password : string) {        
        const res = await api.user.login(email, password);
        const token = res.token;

        // set API Token        
        api.setAccessToken(token);        

        // Set My Info
        MyInfo.getInstace().setMyInfo(res.userInfo);        
    }

    public logout() {
        // clear api request
        api.removeAccessToken();
        
        // Clear My Info
        MyInfo.getInstace().deleteMyInfo();        
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _instance = new AuthManager();
export default _instance;