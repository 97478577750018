import { createTheme } from '@mui/material/styles';
import { koKR } from '@mui/x-date-pickers/locales';

export default createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: "#ffaa17",        
      },
      secondary: {
        main: '#88888C',
      },
    },


    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h5',
            subtitle1: 'h5',
            subtitle2: 'h5',
            body1: 'span',
            body2: 'span',
          },
        },
      },        
    },
        
    typography: {
      fontFamily: [        
        '"Noto Sans KR"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
  

      // Page Title
      h1 : {
        fontWeight : "700",
        fontSize : "2.6rem",        
      },
      // Dialog Title
      h2 : {
        fontWeight : "700",
        fontSize : "1.6rem",        
      },
      h3 : {
        fontWeight : "500",
        fontSize : "1.4rem",        
      },      
      h4 : {
        fontWeight : "500",
        fontSize : "1.2rem",        
      },
      h5 : {
        fontWeight : "400",
        fontSize : "1rem",        
      },
      h6 : {
        fontWeight : "400",
        fontSize : "0.875rem",
        color : "#88888c"
      },
      subtitle1 : {
        fontWeight : "500",
        fontSize : "0.875rem",        
      },
      subtitle2 : {
        fontWeight : "400",
        fontSize : "0.875rem",
        color : "#88888C"
      },
      body1:{
        fontWeight : "400",
        fontSize : "1rem",
      },
      body2:{
        fontWeight : "400",
        fontSize : "0.8175rem",        
      }

    },
  }, koKR);
  