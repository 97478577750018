/**************************************************************************************************
    FileName  : misc/reducer.ts
    Description
      Misc redux reducer

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { UPDATE_PATH_TRACKER_DATA, SHOW_SIDEBAR } from '../action-types';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Interface                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface MiscReducerState {
	pathTrackerTextList : string[],
	isShowSideBar : boolean
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Const                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////
const INIT_STATE : MiscReducerState = {
	pathTrackerTextList : [],
	isShowSideBar : false
};

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function MiscReducer(
  state = INIT_STATE,
  action: {type: string, payload: any}	// eslint-disable-line @typescript-eslint/no-explicit-any
) {
  switch (action.type) {
	case UPDATE_PATH_TRACKER_DATA: {
	return {
		...state,
		pathTrackerTextList : action.payload
	};
	}

	case SHOW_SIDEBAR: {		
		return {
			...state,
			isShowSideBar : action.payload
		};
	}

	default: {
	return state;
	}
}
}