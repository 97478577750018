/**************************************************************************************************
    FileName  : AppToast.tsx
    Description

    Update History 
        2024.07     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Imports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { toast } from 'react-toastify';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Class Implementation                                      //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AppToast {
    ///////////////////////////////////////////////////////////////////////////
    // 								Meber Variables							 //
    ///////////////////////////////////////////////////////////////////////////
    private DEFAULT_OPTIONS = {
        position : toast.POSITION.BOTTOM_CENTER,        
    };

    ///////////////////////////////////////////////////////////////////////////
    // 								Interface functions						 //
    ///////////////////////////////////////////////////////////////////////////
    public success(msg : string) {
        toast.success( msg, this.DEFAULT_OPTIONS);
    }

    public error(msg : string) {        
        toast.error( msg, this.DEFAULT_OPTIONS);
    }

    public warn(msg : string) {
        toast.warn( msg, this.DEFAULT_OPTIONS);
    }

    public info(msg : string) {
        toast.info( msg, this.DEFAULT_OPTIONS);
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Exports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _instance = new AppToast();
export default _instance;