/**************************************************************************************************
    FileName  : SpacePostWritePage.tsx
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
// Import for Css
import "./ExerciseWritePage.scss";
// Import for React
import { useState, useRef } from "react";
// Import for Material UI
import {
    Stack, Button, TextField, Typography
} from "@mui/material";
// Import for Project Base
import { api, appToast, appNavigator } from "libs/stdlib";
// Import for Project Modules
import SHSTextEditor, {TextEditorRefType} from "components/SHSText/SHSTextEditor";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                   Class Implementation                                        //
///////////////////////////////////////////////////////////////////////////////////////////////////
function ExerciseWritePage() {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const [title, setTitle] = useState<string>("");    
    const refTextEditor : TextEditorRefType = useRef(null);
    
    
    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_writePost(title : string, description? : string, relativeFiles? : number[]) {
        await api.postWithCallback(
          `/api/exercise`,
          {title, description, relativeFiles},
          ()=>{ appNavigator.goBack(); }
        );
    }


    /////////////////////////////////////////////////////////////////////////////
    // Functions
    async function onWriteExcerciseClick() {
        const titleParam = title.trim();
        if( titleParam.length === 0 )
            return appToast.error("제목을 입력해 주십시오.");

        const encodeResult = await refTextEditor.current?.encodeAndConfirmTmpImage();        
        const description = encodeResult?.encodedText
        
        await api_writePost(titleParam, description, encodeResult?.relativeFiles);
    }


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="exerciseWritePage-20240615">
            <Typography className="title-label">제목 *</Typography>
            <TextField className="title-text" value={title} onChange={(e)=>setTitle(e.target.value)  }/>

            <Typography className="description-label">내용</Typography>
            <SHSTextEditor refEditor={refTextEditor} />
                        
            <Button 
                className="btn-write" 
                variant="outlined"                 
                onClick={onWriteExcerciseClick}>글쓰기</Button>
        </Stack>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default ExerciseWritePage;
  