/**************************************************************************************************
    File Name   : DataStorage.ts
    Description :
      프로젝트 크기가 커질수록 스토리지에 저장하는 키가 중복되어 의도치 않은 버그가 발생할 수 있다.. 
      이에 각 모듈별로 각자의 저장 공간을 침범하지 않도록 package 이름을 추가로 받아 해당 버그를 방지한다.
      LocalStorage, SessionStorage, Memory 로 구분하여 저장한다.
      
      
    Usage:
      Local Storage :
      DataStorage.getInstace().get("packageName", "Key", null);
      DataStorage.getInstace().set("packageName", "Key", {fcmToken, deviceType});      
      Session Storage : 
      DataStorage.getInstace().session.get("packageName", "Key", null);
      DataStorage.getInstace().session.set("packageName", "Key", {fcmToken, deviceType});
      Memory : 
      DataStorage.getInstace().memory.get("packageName", "Key", null);
      DataStorage.getInstace().memory.set("packageName", "Key", {fcmToken, deviceType});
      
    Update History
      2024.08            BGKim       Create 
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Constants                                                    //
///////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////
//                            DataStorage Class Implementation                                   //
///////////////////////////////////////////////////////////////////////////////////////////////////
// default is using local storage
export default class DataStorage {
    ///////////////////////////////////////////////////////////////////////////
    //                              Static                                   //
    ///////////////////////////////////////////////////////////////////////////
    private static _instance : DataStorage | null = null;
    public static getInstance(): DataStorage {
        if (DataStorage._instance === null)
            DataStorage._instance = new DataStorage();
        return DataStorage._instance;            
    }

    ///////////////////////////////////////////////////////////////////////////
    //                          Member Variables                             //
    ///////////////////////////////////////////////////////////////////////////
    private _memory = new Map();

    ///////////////////////////////////////////////////////////////////////////
    //                              Constructor                              //
    ///////////////////////////////////////////////////////////////////////////
    private constructor() {

    }

    ///////////////////////////////////////////////////////////////////////////
    //                          Private functions                            //
    ///////////////////////////////////////////////////////////////////////////
    private _getKey(packName : string, key : string) {
        return "__app_" + packName + "_" + key;
    }

    private _makeStoreData(value : unknown) {        
        return JSON.stringify( { type : typeof value, data : value } );
    }

    ///////////////////////////////////////////////////////////////////////////
    //                          Public functions                             //
    ///////////////////////////////////////////////////////////////////////////
    public set(packName : string, key : string, value : unknown) {
        const pageKey = this._getKey(packName,key);
        const storeValue = this._makeStoreData(value);
        localStorage.setItem(pageKey, storeValue);
    }

    public get<T>(packName : string, key : string, defaultValue? : T) : T | null {
        const pageKey = this._getKey(packName,key);
        const itemValue = localStorage.getItem(pageKey);
        if( itemValue === null )
            return (defaultValue !== undefined) ? defaultValue : null;

        const valueObject = JSON.parse( itemValue);        
        const data = valueObject.data;        
        return data as T;
    }

    public delete(packName : string, key : string) {
        localStorage.removeItem( this._getKey(packName,key));
    }


    ///////////////////////////////////////////////////////////////////////////
    //                              Properties                               //
    ///////////////////////////////////////////////////////////////////////////
    public readonly memory = {
        set : (packName : string, key : string, value : unknown) => {
            const pageKey = this._getKey(packName,key);
            this._memory.set(pageKey, value);
        },
        get : <T>(packName : string, key : string, defaultValue? : unknown) : T | null => {
            const pageKey = this._getKey(packName, key);
            const rtn = this._memory.get(pageKey) || defaultValue;

            // Map의 반환값은 Value 혹은 undefiend이고 
            // sessionStorage 와 localStorage의 반환값은  string | null이다. 이에 기준을 null로 맞춘다.
            if( rtn === undefined )
                return null;
            else 
                return rtn as T;
        },
        delete : (packName : string, key : string)=>{
            const pageKey = this._getKey(packName, key);
            this._memory.delete(pageKey);
        }
    };



    public readonly session = {
        set : (packName : string, key : string, value : unknown) =>{
            const pageKey = this._getKey(packName,key);
            const storeValue = this._makeStoreData(value);
            sessionStorage.setItem(pageKey, storeValue);
        },
        get : <T>(packName : string, key : string, defaultValue? : unknown) => {
            const pageKey = this._getKey(packName,key);
            const itemValue = sessionStorage.getItem(pageKey);
            if( itemValue === null )
                return (defaultValue !== undefined) ? defaultValue as T : null;

            const valueObject = JSON.parse( itemValue);
            return valueObject.data as T;
        },
        delete : (packName : string, key : string)=>{
            sessionStorage.removeItem( this._getKey(packName,key));
        }
    };   
}