/**************************************************************************************************
    FileName  : DetailPage.tsx
    Description

    Update History 
    	2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import "./ExerciseDetailPage.scss";
import React, {useEffect, useState} from "react";
import { 
    Stack, Typography, IconButton, Menu, MenuItem,
    Box
 } from '@mui/material';
import { useParams } from "react-router-dom";
import { pathParamReader, api, appNavigator, utils, useMyInfo } from "libs/stdlib";
import { ExerciseDetailData } from "types";
import MoreVertIcon from '@mui/icons-material/MoreVert';

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Class Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function DetailPage() {
    const urlParams = useParams();
    const myInfo = useMyInfo();
    const exerciseId = pathParamReader.readPositiveInt(urlParams.exerciseId);
    const [detailInfo, setDetailInfo] = useState<ExerciseDetailData | null>(null);
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);    
    
    
    globalThis.applySmoothScreenPage(useEffect);
    useEffect(()=>{
        (async ()=>{
            await api.getWithCallback<ExerciseDetailData>(`/api/exercises/${exerciseId}`, (data)=>{
                console.info("detailInfo>>", data);
                setDetailInfo(data);
            });
        })();
        utils.gotoTop();
    }, [exerciseId]);

    

    if( detailInfo === null )
        return <></>;


    function showMenu(event: React.MouseEvent<HTMLElement>) {
        setMenuAnchor(event.currentTarget);
    }
    
    function closeMenu() {
        setMenuAnchor(null);
    }

    function onEditExerciseClick() {
        appNavigator.exercise.goEditPage(exerciseId);        
    }

    async function onDeleteExerciseClick() {
        await api.deleteWithCallback(`/api/exercises/${exerciseId}`, ()=>{
            appNavigator.exercise.goList();
        })
    }
    
    
    return (
		<Stack id="pageExerciseDetail-20240616">
            <Stack className="title-container">
                <Typography variant="h2">{detailInfo.title}</Typography>
                <div className="flex-fill"></div>

                {myInfo &&
                    <React.Fragment>
                        <IconButton onClick={showMenu}><MoreVertIcon/></IconButton>
                        <Menu                    
                            anchorEl={menuAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(menuAnchor)}
                            onClose={closeMenu}
                        >
                            <MenuItem onClick={onEditExerciseClick}>수 정</MenuItem>
                            <MenuItem onClick={onDeleteExerciseClick}>삭 제</MenuItem>
                        </Menu>
                    </React.Fragment>                
                }                
            </Stack>
            <hr/>


            {0 < detailInfo.learningList.length &&
                <Stack className="prior-learning-list-container">
                        <Typography variant="h4">선행학습</Typography>
                        <Stack className="learning-list">
                            {detailInfo.learningList.map((learningInfo)=>{
                                return  (
                                    <Box className="card-container" key={learningInfo.id} onClick={()=>appNavigator.learning.goDetailPage(learningInfo.id)}>
                                        <Typography className="item-title" variant="h5" >
                                            {learningInfo.title}
                                        </Typography>                            
                                        
                                    </Box>
                                )
                            })}
                        </Stack>
                        <hr/>
                </Stack>
            }
            
                
            
            
            <pre className="description-container">{detailInfo.description}</pre>
            <hr>
            </hr>
            {/* <Stack className="action-container">
                <Button variant="outlined" endIcon={<ArrowForwardIosIcon/>} onClick={onNextExercise}>다음</Button>                
            </Stack> */}
        </Stack>
    );
}

