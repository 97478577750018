/**************************************************************************************************
    FileName  : spaceRouter.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
import Login from "./Login";
import SignupRequest from "./SignupRequest";
import SignupEmail from "./SignupEmail";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Routes                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////  


const routes = [
    {
        path: "login",
        element: <Login/>,
    },
    {
        path: "signup/request",
        element: <SignupRequest/>,
    },
    {
        path: "signup/email/:signupKey",
        element: <SignupEmail/>,
    },
];

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default routes;