/**************************************************************************************************
    FileName  : Login.tsx
    Description

    Update History       
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./Login.scss";
// Import for React
import React, {useState, useEffect, useRef} from "react";
// Import for Types
import { BuildMode, DefaultErrorType } from "types";
// Import for Material UI
import { Stack, TextField, Typography, Button, Box, ButtonBase } from "@mui/material";
// Import for Project Library
import { appNavigator, appToast, utils } from 'libs/stdlib';
// Import for Project Modules
import authManager from "modules/AuthManager";
import { SERVER_NAME } from "libs/api";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Home Implementation                                          //
///////////////////////////////////////////////////////////////////////////////////////////////////
function Login() {
    /////////////////////////////////////////////////////////////////////////////
    // Const
    let initialEmail = "";
    let initialPassword = "";    
    if( process.env.REACT_APP_BUILD_MODE === BuildMode.development ) {
		initialEmail = "bg@bgprogramming.com";
		initialPassword = "1234";
    }    
    const [email, setEmail] = useState<string>(initialEmail);
    const [password, setPassword] = useState<string>(initialPassword);

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    async function onLoginClick() {
        const emailParam = email.trim();        
        if( emailParam.length === 0 ) {
            return appToast.error("이메일을 입력해 주십시오");
        } else if( utils.isEmailFormat(email) ===false ) {
            return appToast.error("이메일 형식이 올바르지 않습니다.");
        }

        const passwordParam = password.trim();
        if( passwordParam.length === 0 ) {
            return appToast.error("패스워드를 입력해 주십시오");
        }

        
        try {
            await authManager.login(email, password);
            appNavigator.goBack();
        } catch(e : unknown ) {
            appToast.error((e as DefaultErrorType).message);
        }
    }

    function onSignupClick() {
        appNavigator.auth.goRequestSignupPage();
    }

    
    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="loginPage-240606">
            
            <a className="logo">
                <img src="/assets/imgs/logo-light.png" alt="logo"/>
            </a>

            <Stack className="login-panel">
                <TextField 
                    className="input-email"
                    variant="standard" 
                    label="이메일" 
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                />                
                <TextField 
                    variant="standard" 
                    label="비밀번호" 
                    className="input-password" 
                    type="password"
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                />
                                    
                <Button className="btn-login" variant="contained" onClick={onLoginClick}>로그인</Button>


                {/* <SnsLoginView /> */}       

            </Stack>

            {/* <Stack className="signup-panel">
                <Typography variant="body2" className="btn-signup" onClick={onSignupClick}>회원가입</Typography>
            </Stack> */}
        </Stack>
    );
}




function SnsLoginView() {
    const containerRef = useRef<HTMLElement | null>(null);
  
    const [buttonWidth, setButtonWidth] = useState<number>(400);
  
    useEffect(() => {
      if (containerRef.current !== null) {
        const width = containerRef.current.getBoundingClientRect().width;
        setButtonWidth(width);
      }
  
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }, []);
      
  
    return (
      <Box
        className='sns-login-panel'
        ref={containerRef}
      >
        <Box className='google-login-btn'>
          <div id="g_id_onload"
            data-client_id="109779934296-82q1ars2rd2qmk06f525lb751a07bt2g.apps.googleusercontent.com"
            data-login_uri={`${SERVER_NAME}/api/user/sns-login/callback/google`}
            data-auto_prompt="false">
          </div>
          <div
            className="g_id_signin"
            data-type="standard"
            data-size="large"
            data-width={buttonWidth}
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="center">
          </div>
        </Box>
      </Box>
    );
  }


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default Login;