/**************************************************************************************************
    FileName  : MyInfo.ts
    Description
      My Info Singleton Class

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import DataStorage from "./DataStorage";
import { useSelector } from 'react-redux';
import ReduxStore from 'redux/store';
import { updateMyInfo } from 'redux/actions';
import { MyInfoData, IRootState } from 'types';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Constants                                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
const PACKAGE_NAME = 'MyInfo';
const MY_INFO_KEY = 'MyInfo';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class MyInfo {
    private static _instance : MyInfo | null = null;
    public static getInstace() : MyInfo {
        if( MyInfo._instance === null )
            MyInfo._instance = new MyInfo();
        return MyInfo._instance;
    }

    private _myInfo: MyInfoData | null;

    private constructor() {
        this._myInfo = DataStorage.getInstance().get(PACKAGE_NAME, MY_INFO_KEY, null);
        if( this._myInfo !== null ) 
            ReduxStore.dispatch(updateMyInfo(this._myInfo));
    }

    public setMyInfo(myInfo: MyInfoData) {
        this._myInfo = myInfo;
        console.info("myInfo>>>>", myInfo);
        DataStorage.getInstance().set(PACKAGE_NAME, MY_INFO_KEY, myInfo);
        ReduxStore.dispatch(updateMyInfo(myInfo));
    }
    public deleteMyInfo() {
        this._myInfo = null;
        DataStorage.getInstance().delete(PACKAGE_NAME, MY_INFO_KEY);
        ReduxStore.dispatch(updateMyInfo(null));
    }
        

    public isLogin(): boolean {
        return this._myInfo !== null;
    }

    useMyInfo = (): MyInfoData | null => {
        return useSelector((state: IRootState) => state.user);
    };

}

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////

