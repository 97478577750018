import {error} from "./error";

class PathParamReader {
    public readPositiveInt(param : string | undefined) : number {
        if( param === undefined )
            throw error.newInstanceInvalidPathParam();

        const result = parseInt(param);
        if( result < 0 )
            throw error.newInstanceInvalidPathParam();
        return result;
    }
}

const _instance = new PathParamReader();
export default _instance;