/**************************************************************************************************
    FileName  : App.tsx
    Description

    Update History       
      2023.10     BGKim     Create
**************************************************************************************************/


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Imports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
// Import for Types
// Import for React
// Import for Material UI
// Import for Package Library
// Import for Project Library
// Import for Project Modules
// Import for {API} Internal Modules
// Import for Images


// Import for Css
import React from 'react';
// Import for React
import { useNavigate, Outlet } from 'react-router-dom';
import { appNavigator } from 'libs/stdlib';

// for Toast
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                            Types                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Class Implementation                                      //
///////////////////////////////////////////////////////////////////////////////////////////////////

export default function App() {
    ///////////////////////////////////////////////////////////////////////////
    //                              Meber Variables                          //
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    //                                Constructor                            //
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    //                            Interface functions                        //
    ///////////////////////////////////////////////////////////////////////////


    const navigate = useNavigate();
    appNavigator.init(navigate);
    
    return (
        <>
        <Outlet/>
        <ToastContainer/>
        </>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Exports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////