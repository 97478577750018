/**************************************************************************************************
    FileName  : Editor.ts
    Description

    Update History
	  	2024.08		BGKim		Add code style
      	2023.06     BGKim     	Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Imports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React  from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import './Editor.scss';
import { ElementType, TextStyle, SHSElementProps } from "../Define";
import {utils} from "../Utils";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                            Types                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////

// Must define Special Key code
// Normal character key code is can not detect on andorid virtual keyboard.
const enum KeyCode {
	backspace = "Backspace",
	enter = "Enter",
	esc = "Escape",
	space = "Space",
	up = "ArrowUp",
	down = "ArrowDown",
	delete = "Delete"
}

enum TextAreaMode {
	MODE_NORMAL = "normal",
	MODE_SUGGESTION = "suggestion"
}

interface TextAreaData {
	text : string;
	isFocus : boolean;
	cursorPos : number;
	textStyle : TextStyle;
}

interface EditorStyle {
	textColor : string;
}

interface SHSTextAreaProps extends SHSElementProps{
	data : TextAreaData;
	elementKey : number;
	type : ElementType;
	editorStyle : EditorStyle;
	placeholder? : string;	
	notifyUpdateEditorData : (elementKey : number, type : ElementType, data : TextAreaData)=>void;
	notifyLastCursorPos : (elementKey : number, cursorPos : number, text : string)=>void;
	
	updateSuggestionSpecialKeyCode : (suggestionSpecialKeycode : number)=>void;
	requestDeleteTextAreaWithBackspace : (elementKey : number, text : string)=>void;
	requestInsertYoutubeLinkFromTextArea : (elementKey : number, type : ElementType, elementData : unknown, url : string)=>void;
	requestInsertLinkPreviewFromTextArea : (elementKey : number, type : ElementType, elementData : unknown, url : string)=>void;
	requestLineFeed : (elementKey : number, firstText : string, secondText : string)=>void;
	requestKeyUp : (elementKey : number)=>void;
	requestKeyDown : (elementKey : number)=>void;
	requestDeleteTextAreaWithDeleteKey : (elementKey : number, text : string, caretPos : number)=>void;
	updateSuggestionText : (suggestionText : string)=>void;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Class Implementation                                      //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class SHSTextArea extends React.Component<SHSTextAreaProps> {
	refText : React.RefObject<HTMLTextAreaElement>;
	mode : TextAreaMode;
	state : {
		updateCount : number,
		text : string,
		mode : TextAreaMode.MODE_NORMAL
	};

    constructor(props : SHSTextAreaProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);		
        this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onPaste = this.onPaste.bind(this);
		this.getTextStyle = this.getTextStyle.bind(this);
		this.refText = React.createRef<HTMLTextAreaElement>();
		this.mode = TextAreaMode.MODE_NORMAL;


		// 초기에 에디트 모드에서 이미 입력된 텍스트에서 마지막 문자가 개행이라면 마지막 개행을 지운다.
		// 이는 텍스트 에디터에서 개행이 들어가면 다음 입력을 받기 위해 커서는 다음줄로 넘어가게 된다.
		// 결과적으로 해당 동작은 원하지 않는 줄이 한줄 추가되는 결과가 나온다.		
		const displayText = props.data ? props.data.text : "";
		// if( 2 <= displayText.length && displayText[displayText.length-1] === '\n' && displayText[displayText.length-2] === '\n' )
		//  	displayText = displayText.substring(0, displayText.length-1);

        this.state = {
			text : displayText,
			updateCount : 0,
			mode : TextAreaMode.MODE_NORMAL
		};
	}

	updateScreen() {
		this.setState({updateCount : this.state.updateCount+1});
	}



    componentDidMount() {
        if( this.refText && this.refText.current && this.props.data && this.props.data.isFocus ) {
			if( this.props.data.cursorPos !== undefined && 0 <= this.props.data.cursorPos ) {
				this.refText.current.selectionStart = this.props.data.cursorPos;
				this.refText.current.selectionEnd = this.props.data.cursorPos;
			}			
			this.refText.current.focus();
        }
	}

	


	// KeyDown 에서는 특수문자를 판별하여 해당 입력을 처리할지 여부를 결정한다.
    onKeyDown(e : React.KeyboardEvent<HTMLTextAreaElement>) {		
		if( e.code === KeyCode.backspace && e.currentTarget.selectionStart === 0 && e.currentTarget.selectionEnd === 0)  {
			this.props.requestDeleteTextAreaWithBackspace(
				this.props.elementKey,
				e.currentTarget.value
			);
			e.preventDefault();
			return ;
		}
		
		switch(e.code) {
			case KeyCode.enter: {
				const inputString = e.currentTarget.value;

				// TODO : 현재 라인을 식별해서 현재 라인의 위 아래에 컴포넌트를 붙인다.
				// 지금은 한줄이 URL 일 경우에만 처리

				// const currentLineEndPos = e.currentTarget.selectionStart;
				// let currentLineStartPos = currentLineEndPos - 1;
				// for( let i = currentLineStartPos;		0 <= i;		--i  ) {
				// 	if( inputString[i] === "\n" )  {
				// 		currentLineStartPos = i + 1;
				// 		break;
				// 	}
				// }				

				if( utils.isUrl(inputString) === true )  {
					if( utils.isUrlYoutube(inputString) === true ) {
						this.props.requestInsertYoutubeLinkFromTextArea(
							this.props.elementKey,
							this.props.type,
							{ text : e.currentTarget.value },
							inputString
						);
					}
					else  {
						this.props.requestInsertLinkPreviewFromTextArea(
							this.props.elementKey,
							this.props.type,
							{ text : e.currentTarget.value },
							inputString
						);
					}
					e.preventDefault();
				}
				
				return ;
			}
			
			case KeyCode.delete :  {
				if( e.currentTarget.selectionStart === e.currentTarget.value.length ) {
					this.props.requestDeleteTextAreaWithDeleteKey(this.props.elementKey, e.currentTarget.value, e.currentTarget.selectionStart);
					return ;
				}
				break;
			}
			default :
				break;
		}
	}


    onChange(e : React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({text :  e.target.value});
	}

	updateText() {
		if( this.refText && this.refText.current && 0 <= this.refText.current.value.length  )  {
			this.props.notifyUpdateEditorData(
				this.props.elementKey,
				this.props.type,
				{ ...this.props.data, text : this.refText.current.value, cursorPos : this.refText.current.selectionStart   }
			);
		}
	}
    onBlur() {
		this.updateText();		
		if( this.refText.current ) {
			this.props.notifyLastCursorPos(this.props.elementKey, this.refText.current.selectionStart, this.refText.current.value );
		}
	}
    onFocus() {		
		this.props.notifySelectedItem( this.props.elementKey );
		this.updateScreen();
	}	

	getTextStyle() {		
		const textStyle = this.props.data.textStyle;
        const textElementStyle : React.CSSProperties = {}
        if( this.props.editorStyle)
            textElementStyle.color = this.props.editorStyle.textColor;


			

		if( document.activeElement === this.refText.current  ) {
			textElementStyle.backgroundColor = "#333";
		}
		
		if(textStyle === undefined || textStyle === null )
			return textElementStyle;

		switch(textStyle) {
			case TextStyle.large:
				textElementStyle.fontWeight = "bold";
            	textElementStyle.fontSize = 16;
				break;
			case TextStyle.extraLarge:
				textElementStyle.fontWeight = "bold";
            	textElementStyle.fontSize = 18;
				textElementStyle.margin = "32px 0px 8px"
				break;
			case TextStyle.quotation:
				textElementStyle.fontSize = 16;
            	textElementStyle.marginLeft = 8;
				break;
			case TextStyle.code:
				textElementStyle.fontFamily = "NanumGothicCoding";
				textElementStyle.padding = "16px 8px";
				textElementStyle.border = "1px solid #454545";
				textElementStyle.borderRadius = "4px";
                textElementStyle.margin = "16px 0px";				
				break;
			default:
				textElementStyle.fontSize = 16;
				break;				
		}

		
        return textElementStyle;
	}

	onPaste() {
		// paste 이벤트가 발생하면
		console.info("data >>>", this.props.data);

	}


    render() {
		const textStyle = this.props.data.textStyle;


        return (
			<div className={"text-area-container"}  >
				{textStyle===TextStyle.bullet &&
					<div style={{
                        marginRight:4,
                        fontSize:16,
                        display : "flex", alignItems : "center", height:"100%", marginTop : 2,
                        color : this.props.editorStyle ? this.props.editorStyle.textColor : "inherit"
                    }}>•</div>
				}
				
				{textStyle===TextStyle.quotation &&
					<div style={{backgroundColor:"#c4c4c4", width : 1, height:"100%", position:"absolute"}}> </div>
				}

				<TextareaAutosize
					className="spacehub-textarea"
                    ref={this.refText}
                    onChange={this.onChange}
					onKeyDown={this.onKeyDown}					
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onPaste={this.onPaste}
					value={this.state.text}
					style={this.getTextStyle()}
                    placeholder={this.props.placeholder}
				/>
			</div>

        );
    }
}
