/**************************************************************************************************
    FileName  : MainLayout.tsx
    Description

    Update History 
		2023.11     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import "./MainLayout.scss";
import React, { useEffect } from "react";
import { Box, Stack } from '@mui/material';
import Navbar from "pages/Navbar";
import Footer from "pages/Footer";
import { Outlet } from "react-router-dom";





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Class Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function MainLayout() {	
    return (
		<Stack id="layoutMain">
			<Box id="smooth-wrapper">
				<Navbar/>				
				<Box id="smooth-content">				
					<main id="mainContainer">
						<Outlet/>
					</main>
					<Footer/>
				</Box>
			</Box>
    	</Stack>
    );
}
