/**************************************************************************************************
    FileName  : SHSYoutube.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import Youtube from 'react-youtube';
import { useResizeDetector } from 'react-resize-detector';
import { SHSElementProps, SHSYoutubeData, YoutubeType } from '../Define';


///////////////////////////////////////////////////////////////////////////////////////////////////
// This is abstract static class function
export const getYoutubeType = function () {
    return "youtube";
};
///////////////////////////////////////////////////////////////////////////////////////////////////

interface SHSYoutubeProps extends SHSYoutubeData {
    componentType : string;    
}


function YoutubeContainer(props : SHSYoutubeData )  {
    const { width,  ref } = useResizeDetector();    
    let containerWidth = width;
    let containerHeight = 640;


    if( width ) {
        if( props.youtubeType === YoutubeType.shorts )  {
            if( 270 <= width ) {
                containerWidth = 270;
                containerHeight = 480;
            } else {
                // Target Rate : 360x640
                // x = screenWidth * 640 / 360;
                containerHeight = width * 640 / 360;
            }
        } else {
            // Target Rate : 640x360
            // x = screenWidth * 360 / 640;
            containerHeight = width * 360 / 640;
        }
    }
    
    
    return (
        <div className="youtube-player-container" style={{width : containerWidth, height : containerHeight}} ref={ref}>
                <Youtube
                    className="spacehub-youtube"                    
                    videoId={props.id}
                    opts={{
                        playerVars: {
                            // youtube iframe을 로드하면 브라우저 콘솔에 아래와 같은 에러메시지 출력됨.
                            // Failed to execute 'postMessage' on 'DOMWindow':
                            // The target origin provided ('https://www.youtube.com') does not match the recipient window's origin (${window.origin}).
                            // 검색 결과 유튜브 iframe의 오래된 버그(?) 이고, 프로그램 동작에 영향이 없다고 함.
                            // https://developers.google.com/youtube/iframe_api_reference 구글 공식 문서에 들어간 후 콘솔에 출력된 내용을 보면, 동일한 에러메시지 출력되어 있음.

                            // 아래 속성 1로 설정하지 않으면 iOS에서 인라인 재생이 안됨.
                            playsinline: 1,
                        }
                    }}
                />
        </div>
    );

}


export default class SHSYoutube extends React.Component<SHSYoutubeProps> {    
    render() {        
        const props = this.props;        
        console.info("props>>>", props);
        return(
            <YoutubeContainer id={props.id} youtubeLink={props.youtubeLink} youtubeType={props.youtubeType} />
        );

    }
}
